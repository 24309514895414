import { Slot, Slottable } from '@radix-ui/react-slot';
import clsx from 'clsx';
import { forwardRef } from 'react';
import {
  postTagButtonContainer,
  PostTagButtonVariant,
  postTagButtonVariants,
  postTagButtonWithEndIcon,
  postTagButtonWithStartIcon,
} from './post-tag-button.css';

export type TagButtonProps = {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  asChild?: boolean;
  variant?: PostTagButtonVariant;
} & React.ComponentPropsWithoutRef<'button'>;

export const PostTagButton = forwardRef<HTMLButtonElement, TagButtonProps>(
  function PostTagButton(
    {
      startIcon,
      endIcon,
      className,
      asChild,
      variant = 'secondary',
      children,
      ...rest
    },
    ref,
  ) {
    const Component = asChild ? Slot : 'button';

    return (
      <Component
        ref={ref}
        type="button"
        className={clsx(
          className,
          postTagButtonContainer,
          postTagButtonVariants[variant],
          startIcon && postTagButtonWithStartIcon,
          endIcon && postTagButtonWithEndIcon,
        )}
        {...rest}
      >
        {startIcon}
        <Slottable>{children}</Slottable>
        {endIcon}
      </Component>
    );
  },
);
